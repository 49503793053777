import { IReward } from '~/Reducers/ReducerApp'
import { GameItemReward } from '~/types'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import styles from '~/Components/Dialogs/RewardsDialog/RewardsDialog.scss'
import RewardCompensation from '~/Components/RewardCompensation/RewardCompensation'
import RewardShip from '~/Components/RewardShip/RewardShip'
import { UnitedReward } from '~/Components/UnitedReward/UnitedReward'

const combineRewards = (rewards: Array<IReward>) => {
    const allRewards: GameItemReward[] = []
    const combinedRewards: GameItemReward[] = []
    rewards?.forEach((reward: IReward) => {
        reward.gameRewards.forEach((gameReward: GameItemReward) => {
            allRewards.push(gameReward)
        })
    })
    allRewards?.forEach((gameReward: GameItemReward) => {
        let added = false
        for (let i = 0; i < combinedRewards.length; i++) {
            if (combinedRewards[i].id == gameReward.id && combinedRewards[i].type == gameReward.type) {
                combinedRewards[i].amount += gameReward.amount
                added = true
                break
            }
        }
        if (!added) combinedRewards.push(cloneDeep(gameReward))
    })

    return combinedRewards
}

export const renderRewards = (
    isShip = false,
    rewards: Array<IReward>,
    eventum: number,
    compensations: Array<CompensationItem>,
    ids: Array<string>,
) => {
    const compensationMultiplier = eventum
    const icons: Array<React.ReactNode> = []

    if (compensationMultiplier > 0 && !isShip) {
        compensations.forEach((compensationItem) => {
            icons.push(
                <div className={styles.rewardWrapper} key={`reward-${compensationItem.type}-${compensationItem.amount}`}>
                    <RewardCompensation
                        key={`compensation-reward-${compensationItem.type}`}
                        {...compensationItem}
                        multiplier={compensationMultiplier}
                    />
                </div>,
            )
        })
    }

    const filteredRewards = rewards.filter((reward) => {
        let isShipReward = false
        const found = false
        reward.gameRewards.forEach((gameReward) => {
            if (found === false && gameReward.type === 'ship') {
                isShipReward = true
            }
        })
        return isShip === isShipReward && ids.includes(reward.id) && reward.gameRewards && reward.gameRewards.length > 0
    })

    if (filteredRewards.length < 1) return false

    if (isShip) {
        filteredRewards.reverse().forEach((reward: IReward) => {
            const shipId = reward.gameRewards[0].id
            icons.push(<RewardShip id={shipId} fixedTooltip={false} reward={reward} isBig key={`RewardShip-${shipId}`} />)
        })
        return icons
    } else {
        const unitedRewards = combineRewards(filteredRewards)
        unitedRewards.map((reward: IReward) => {
            icons.push(<UnitedReward rewards={filteredRewards} {...reward} hideText={true} />)
        })
    }
    return icons
}
