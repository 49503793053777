import { ACTION_IDS, DWH_EXPORT_EVENTS } from '~/constants'
import { playBoosterWindowOpen } from '@wg/web2clientapi/sound'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { State } from '~/Reducers'
import { IBooster } from '~/Reducers/ReducerApp'
import animationIterator from '~/utils/animationIterator'
import { afterSkipVideo, completeAction, setPredictProgress, setSelectedBooster, switchSync, updateAvailableRewards } from './ActionApp'
import dwhExport from '~/api/dwhExport'

/*
All action types constants should include "DIALOG" substring if you use them to open/close dialog windows
Use "OPEN" & "CLOSE" substrings inside action types for correct middleware work.
*/
export const OPEN_BUNDLE_BUY_DIALOG = 'OPEN_BUNDLE_BUY_DIALOG'
export const CLOSE_BUNDLE_BUY_DIALOG = 'CLOSE_BUNDLE_BUY_DIALOG'

export const OPEN_START_BUY_DIALOG = 'OPEN_START_BUY_DIALOG'
export const openStartBuyDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    dispatch({
        type: OPEN_START_BUY_DIALOG,
    })
}
export const CLOSE_START_BUY_DIALOG = 'CLOSE_START_BUY_DIALOG'
export const closeStartBuyDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(setPredictProgress(0))
    dispatch({
        type: CLOSE_START_BUY_DIALOG,
    })
}
export const OPEN_PROGRESS_BAR_PORTAL = 'OPEN_PROGRESS_BAR_PORTAL'
export const openProgressBarPortal = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_PROGRESS_BAR_PORTAL,
    })
}
export const CLOSE_PROGRESS_BAR_PORTAL = 'CLOSE_PROGRESS_BAR_PORTAL'
export const closeProgressBarPortal = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_PROGRESS_BAR_PORTAL,
    })
}
export const OPEN_WALLET_PORTAL = 'OPEN_WALLET_PORTAL'
export const openWalletPortal = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_WALLET_PORTAL,
    })
}
export const CLOSE_WALLET_PORTAL = 'CLOSE_WALLET_PORTAL'
export const closeWalletPortal = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_WALLET_PORTAL,
    })
}
export const OPEN_MULTIPLE_BUY_DIALOG = 'OPEN_MULTIPLE_BUY_DIALOG'
export const openMultipleBuyDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_MULTIPLE_BUY_DIALOG,
    })
}
export const CLOSE_MULTIPLE_BUY_DIALOG = 'CLOSE_MULTIPLE_BUY_DIALOG'
export const closeMultipleBuyDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(setPredictProgress(0))
    dispatch({
        type: CLOSE_MULTIPLE_BUY_DIALOG,
    })
}
export const OPEN_FINAL_DIALOG = 'OPEN_FINAL_DIALOG'
export const openFinalDialog = (shipName: string): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_FINAL_DIALOG,
        payload: {
            shipName,
        },
    })
}
export const CLOSE_FINAL_DIALOG = 'CLOSE_FINAL_DIALOG'
export const closeFinalDialog = (shipName?: string): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    animationIterator.actionFinished()
    dispatch(completeAction(ACTION_IDS.GOLD_REWARD_POPUP))
    dispatch(updateAvailableRewards(true))

    dispatch({
        type: CLOSE_FINAL_DIALOG,
    })
}
export const OPEN_BUYOUT_DIALOG = 'OPEN_BUYOUT_DIALOG'
export const openBuyoutDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_BUYOUT_DIALOG,
    })
}
export const CLOSE_BUYOUT_DIALOG = 'CLOSE_BUYOUT_DIALOG'
export const closeBuyoutDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_BUYOUT_DIALOG,
    })
}
export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG'
export const openErrorDialog = (error: Error): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_ERROR_DIALOG,
        payload: {
            error: error,
        },
    })
}
export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG'
export const closeErrorDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_ERROR_DIALOG,
    })
}
export const OPEN_WELCOME_DIALOG = 'OPEN_WELCOME_DIALOG'
export const openWelcomeDialog = (hideMessage?: boolean): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const isWelcomeMessageVisible = !(hideMessage === true)

    dispatch({
        type: OPEN_WELCOME_DIALOG,
        payload: {
            isWelcomeMessageVisible: isWelcomeMessageVisible,
        },
    })
}
export const CLOSE_WELCOME_DIALOG = 'CLOSE_WELCOME_DIALOG'
export const closeWelcomeDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_WELCOME_DIALOG,
    })
}

export const OPEN_BOOSTER_DIALOG = 'OPEN_BOOSTER_DIALOG'
export const openBoosterDialog = (booster: IBooster): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    playBoosterWindowOpen()
    dispatch(setSelectedBooster(booster))
    dispatch({
        type: OPEN_BOOSTER_DIALOG,
    })
}
export const CLOSE_BOOSTER_DIALOG = 'CLOSE_BOOSTER_DIALOG'
export const closeBoosterDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_BOOSTER_DIALOG,
    })
    dispatch(setSelectedBooster(null))
}
export const OPEN_RULES_DIALOG = 'OPEN_RULES_DIALOG'
export const openRulesDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_RULES_DIALOG,
    })
    dwhExport.send(DWH_EXPORT_EVENTS.SHOW_RULES)
}
export const CLOSE_RULES_DIALOG = 'CLOSE_RULES_DIALOG'
export const closeRulesDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_RULES_DIALOG,
    })
}
export const SHOW_VIDEO_PLAYER = 'SHOW_VIDEO_PLAYER'
export const showVideoPlayer = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(switchSync(false))
    dispatch({
        type: SHOW_VIDEO_PLAYER,
    })
}
export const HIDE_VIDEO_PLAYER = 'HIDE_VIDEO_PLAYER'
export const hideVideoPlayer = (videoType: VideoType): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const isIntro = videoType === "intro"
    !isIntro && dispatch(switchSync(true))
    dispatch({
        type: HIDE_VIDEO_PLAYER,
        isIntro
    })
    dispatch(afterSkipVideo(videoType))
}
export const SHOW_INTRO_VIDEO = 'SHOW_INTRO_VIDEO'
export const showIntroVideo = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_INTRO_VIDEO,
    })
    dispatch(showVideoPlayer())
}
export const SHOW_LAUNCH_VIDEO = 'SHOW_LAUNCH_VIDEO'
export const showLaunchVideo = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_LAUNCH_VIDEO,
    })
    dispatch(showVideoPlayer())
}
export const SHOW_COMPLETE_BLUEPRINTS_VIDEO = 'SHOW_COMPLETE_BLUEPRINTS_VIDEO'
export const showCompleteBlueprintsVideo = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_COMPLETE_BLUEPRINTS_VIDEO,
    })
    dispatch(showVideoPlayer())
}
export const SHOW_FINAL_VIDEO = 'SHOW_FINAL_VIDEO'
export const showFinalVideo = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_FINAL_VIDEO,
    })
    dispatch(showVideoPlayer())
}

export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const showOverlay = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_OVERLAY,
    })
}

export const HIDE_OVERLAY = 'HIDE_OVERLAY'
export const hideOverlay = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: HIDE_OVERLAY,
    })
}

export const SHOW_LAST_CHANCE_SCREEN = 'SHOW_LAST_CHANCE_SCREEN'
export const showLastChanceScreen = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    if (state.ReducerApp.shipLevel === 1) {
        dispatch({
            type: SHOW_LAST_CHANCE_SCREEN,
        })
    }
}

export const SHOW_LOAD_ERROR = 'SHOW_LOAD_ERROR'
export const showLoadError = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_LOAD_ERROR,
    })
}

export const HIDE_LOAD_ERROR = 'HIDE_LOAD_ERROR'
export const hideLoadError = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: HIDE_LOAD_ERROR,
    })
}

export const OPEN_BUY_GOLD_DIALOG = 'OPEN_BUY_GOLD_DIALOG'
export const openBuyGoldDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_BUY_GOLD_DIALOG,
    })
    dwhExport.send(DWH_EXPORT_EVENTS.BUY_GOLD)
}

export const CLOSE_BUY_GOLD_DIALOG = 'CLOSE_BUY_GOLD_DIALOG'
export const closeBuyGoldDialog = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_BUY_GOLD_DIALOG,
        payload: {
            skipMiddleware: true,
        },
    })
}

export const SHOW_BLUEPRINTS_VIEWER = 'SHOW_BLUEPRINTS_VIEWER'
export const showBlueprintsViewer = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_BLUEPRINTS_VIEWER,
    })
}
export const HIDE_BLUEPRINTS_VIEWER = 'HIDE_BLUEPRINTS_VIEWER'
export const hideBlueprintsViewer = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: HIDE_BLUEPRINTS_VIEWER,
    })
}
export const SHOW_DOWNLOAD_GAME_POPUP = 'SHOW_DOWNLOAD_GAME_POPUP'
export const showDownloadGame = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_DOWNLOAD_GAME_POPUP,
    })
}
export const HIDE_DOWNLOAD_GAME_POPUP = 'HIDE_DOWNLOAD_GAME_POPUP'
export const hideDownloadGame = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: HIDE_DOWNLOAD_GAME_POPUP,
    })
}

export const SHOW_REST_REWARDS_DIALOG = 'SHOW_REST_REWARDS_DIALOG'
export const showRestRewards = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SHOW_REST_REWARDS_DIALOG,
    })
}
export const HIDE_REST_REWARDS_DIALOG = 'HIDE_REST_REWARDS_DIALOG'
export const hideRestRewards = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: HIDE_REST_REWARDS_DIALOG,
        payload: {
            skipMiddleware: true,
        },
    })
}

export const OPEN_PURCHASE_CONFIRMATION = 'OPEN_PURCHASE_CONFIRMATION'
export const openPurchaseConfirmation = (price: number, rewards: Array<string>): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: OPEN_PURCHASE_CONFIRMATION,
        payload: {
            price: price,
            rewards: rewards,
        },
    })
}
export const CLOSE_PURCHASE_CONFIRMATION = 'CLOSE_PURCHASE_CONFIRMATION'
export const closePurchaseConfirmation = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: CLOSE_PURCHASE_CONFIRMATION,
        payload: {
            skipMiddleware: true,
        },
    })
}
