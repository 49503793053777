import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    hideRepeatSidebar as hideRepeatSidebarAction,
    repeatBuildingProcess as repeatBuildingProcessAction,
    showRepeatSidebar as showRepeatSidebarAction,
} from '~/Actions/ActionApp'
import { CountDown } from '~/Components'
import Link from '~/Components/Link/Link'
import { HeaderEndEventMessage, HeaderEndMessage } from '~/Components/Messages/Messages'
import { DWH_EXPORT_EVENTS } from '~/constants'
import { State } from '~/Reducers'
import { appIsReady, isQuestsComplete } from '~/selectors'
import * as Api from '~/types'
import styles from './Header.scss'
import dwhExport from '~/api/dwhExport'
import Stage from '~/Components/Stage/Stage'
import { isInGame } from '~/Reducers/ReducerApp'
import { Button as DockyardButton } from '~/Components/Buttons/Buttons'
import ClientApi from '~/clientApi'
import { Divider } from '@wg/wows-react-uikit'
import { Simulate } from 'react-dom/test-utils';

export interface HeaderState {
    progressFinishTime: number
    isRepeating: boolean
    isRepeatSidebarVisible: boolean
    appIsReady: boolean
    isQuestsComplete: boolean
    shipLevel: number
    shipLevels: Array<Api.ShipLevel>
    animationShipLevel: number
    closeTime: number
    lastLevel: number
    progress: number
    isSSEFinished: boolean
    sseSectionName: string
}

const stateSelector = (state: State): HeaderState => {
    return {
        progressFinishTime: state.ReducerApp.progressFinishTime,
        isRepeating: state.ReducerApp.isRepeating,
        isRepeatSidebarVisible: state.ReducerApp.isRepeatSidebarVisible,
        shipLevel: state.ReducerApp.shipLevel,
        appIsReady: appIsReady(state),
        isQuestsComplete: isQuestsComplete(state),
        shipLevels: state.ReducerApp.shipLevels,
        animationShipLevel: state.ReducerApp.animationShipLevel,
        closeTime: state.ReducerApp.closeTime,
        lastLevel: state.ReducerApp.lastLevel,
        progress: state.ReducerApp.account?.progress || 0,
        isSSEFinished: appIsReady(state) && isQuestsComplete(state),
        sseSectionName: state.ReducerApp.sseSectionName,
    }
}

const Header = () => {
    const dispatch = useDispatch()
    const state = useSelector<State, HeaderState>(stateSelector, equal)
    const [isEndReached, setIsEndReached] = useState<boolean>(false)
    const {
        lastLevel,
        progressFinishTime,
        isRepeatSidebarVisible,
        shipLevel,
        appIsReady,
        isQuestsComplete,
        shipLevels,
        animationShipLevel,
        closeTime,
        isSSEFinished,
        sseSectionName,
    } = state

    useEffect(() => {
        if (animationShipLevel === lastLevel) {
            setIsEndReached(true)
        } else {
            setIsEndReached(false)
        }
    }, [shipLevel, lastLevel, animationShipLevel])

    const showRepeatSidebar = () => {
        dispatch(showRepeatSidebarAction())
    }
    const hideRepeatSidebar = () => {
        dispatch(hideRepeatSidebarAction())
    }
    const repeatBuildingProcess = () => {
        dispatch(repeatBuildingProcessAction())
        dwhExport.send(DWH_EXPORT_EVENTS.REPEAT_ANIMATION)
    }

    const { t } = useTranslation()

    const onBurgerClick = () => {
        if (isRepeatSidebarVisible) {
            hideRepeatSidebar()
        } else {
            showRepeatSidebar()
            dwhExport.send(DWH_EXPORT_EVENTS.OPEN_LEVELS)
        }
    }

    const getStageTooltipText = () => {
        let descr = ''
        shipLevels.forEach((shipLevel) => {
            if (shipLevel.level === animationShipLevel) {
                descr = shipLevel.description
            }
        })
        return descr
    }

    const now = moment.utc().unix() * 1000
    const isEventFinished = now >= progressFinishTime && appIsReady
    const isSSEVisible = !(isSSEFinished || isEventFinished) && isInGame
    const isQuestsCompleteWithEvent = isQuestsComplete || isEventFinished
    const isEndTimerVisible = now < progressFinishTime && shipLevel !== lastLevel

    const wrapperClassname = classnames(styles.wrapper, {
        [styles.wrapperVisible]: appIsReady,
    })

    return (
        <>
            <div className={styles.topOverlay} />
            <div className={wrapperClassname}>
                <div className={styles.headerContent}>
                    <div className={styles.subtitle}>
                        <HeaderEndEventMessage isVisible={isEventFinished && !isEndReached} eventEndDate={closeTime} />
                        <HeaderEndMessage lastLevel={lastLevel} isVisible={isEndReached} isQuestsComplete={isQuestsCompleteWithEvent} />
                        {isEndReached || isEventFinished ? null : (
                            <>
                                <Stage isVisible={appIsReady} shipLevel={animationShipLevel} tooltipText={getStageTooltipText()} />
                                <Divider type={'major'} className={styles.line} />
                            </>
                        )}
                        {isEndTimerVisible && (
                            <div className={styles.bigCountdown}>
                                <CountDown date={progressFinishTime} isLarge tooltipText={t('Время отведенное на строительство корабля')} closeDate={closeTime} />
                            </div>
                        )}
                        {isSSEVisible && (
                            <div className={styles.sseBlock}>
                                <DockyardButton
                                    label={t('К боевым задачам')}
                                    onClick={() => ClientApi.navigateToSse(sseSectionName)}
                                    className={styles.sseButton}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.actionButtons}>
                    {isRepeatSidebarVisible ? (
                        <Link onClick={onBurgerClick} icon={'close'} text={t('Закрыть список этапов')} />
                    ) : (
                        <Link onClick={onBurgerClick} icon={'menu'} text={t('Открыть список этапов')} />
                    )}
                </div>
            </div>
        </>
    )
}

export default memo(Header)
