import { Interpolate } from '@wg/wows-react-uikit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { gold } from '~/wowsEntities'
import styles from './BoosterCard.module.scss'
import { playButtonClickSound } from '@wg/web2clientapi/sound'
import classnames from 'classnames'
import { Button } from '~/Components/Buttons/Buttons'
import GotItIcon from '~/Components/BoosterCard/images/GotItIcon'
import UnselectIcon from '~/Components/BoosterCard/images/UnselectIcon'
import classNames from 'classnames'

export interface BundleCard_Props {
    id: string
    index: number
    count?: number
    price: number
    isBought: boolean
    onBuy?: () => void
    title?: string
    description?: string
    allBoostersCount?: number
    singleBoosterPrice: number
    isBlocked?: boolean
    onClick?: (count: number, id: string, priceDiff: number) => void
    selected?: boolean
}

const BoosterCard = (props: BundleCard_Props) => {
    const { id, index, count, price, title, singleBoosterPrice, onClick, isBlocked, selected } = props
    const { t } = useTranslation()

    const bundleWrapper = classnames(
        (!index && styles.smallBundleCard) || styles.bigBundleCard,
        selected && styles.selected,
        isBlocked && !selected && styles.blocked,
    )
    const sale = Math.floor(100 - (100 * price) / (count * singleBoosterPrice))
    const priceDiff = count * singleBoosterPrice - price

    const selectBooster = useCallback(() => {
        playButtonClickSound()
        onClick(selected ? -count : count, id, priceDiff)
    }, [selected])

    const subtitle = () => {
        if (count > 1) {
            return (
                <Interpolate
                    str={t('%(bonus)s этапа строительства', {
                        count: count,
                    })}
                    bonus={count}
                />
            )
        } else {
            return t('Любое количество этапов')
        }
    }

    return (
        <>
            <div className={bundleWrapper} onClick={selectBooster}>
                {selected && <UnselectIcon className={styles.close} onClick={selectBooster} />}
                <div className={styles.titleBlock}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.levelsInfo}>{subtitle()}</div>
                </div>
                <div className={styles.purchaseBlock}>
                    <div className={'priceBlock'}>
                        <div className={'sale'}>{sale}%</div>
                        <div className={'price'}>
                            <div className={'oldPrice'}>{gold(count * singleBoosterPrice, false, true, 'oldPrice')}</div>
                            <div className={'newPrice'}>{gold(price, false)}</div>
                        </div>
                    </div>
                    {isBlocked && !selected ? null : selected ? (
                        <div className={styles.added}>
                            <GotItIcon />
                            {'Добавлен'}
                        </div>
                    ) : (
                        <Button label={'Добавить'} onClick={selectBooster} className={styles.add} disabled={isBlocked} />
                    )}
                </div>
            </div>
        </>
    )
}

export default BoosterCard
