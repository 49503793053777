import { Actions } from '~/Actions'
import {
    APP_IS_READY,
    CLEAR_BOOSTER_ID,
    GET_BLUEPRINTS_SVG_DATA,
    GET_DATA,
    HIDE_REPEAT_SIDEBAR,
    SET_ANIMATION_STATE,
    SET_COMPLETE_ACTION_PENDING_STATE,
    SET_GET_DATA_PENDING_STATE,
    SET_ISSUE_REWARDS_PENDING_STATE,
    SET_PREDICT_PROGRESS,
    SET_PROGRESS_SHIP_LEVEL,
    SET_REPEATING_STATE,
    SET_SELECTED_BOOSTER,
    SET_SHOP_LOADING_STATE,
    SET_TARGET_LEVEL,
    SHOW_REPEAT_SIDEBAR,
    SWITCH_SYNC,
    TURN_GUIDE_OFF,
    TURN_GUIDE_ON,
    UPDATE_ACCOUNT,
    UPDATE_AVAILABLE_REWARDS,
    UPDATE_BACK_BUTTON_VISIBILITY,
    SET_PAUSE_ANIMATION,
    SET_NEXT_STEP,
    SET_PREVIOUS_STEP,
    ENABLE_BLUR,
    DISABLE_BLUR,
    SET_TIMINGS,
    SET_USED_STARTERS,
} from '~/Actions/ActionApp'
import { SET_BUY_BOOSTER_PENDING_STATE } from '~/Actions/ActionBuy'
import {
    BROWSER_IS_READY,
    SET_SET_SHIP_LEVEL_PENDING_STATE,
    SET_SHIP_LEVEL,
    SET_WORLD_RENDERER_STATE,
    UPDATE_ANIMATION_LEVEL,
    UPDATE_ANIMATION_SUBTITLE,
    UPDATE_CLIENT_STATE,
} from '~/Actions/ActionClient'
import { SET_DEFAULT_DATES, SET_EVENT_END_DATE, SET_EVENT_START_DATE } from '~/Actions/ActionDev'
import { SHOW_COMPLETE_BLUEPRINTS_VIDEO, SHOW_FINAL_VIDEO, SHOW_INTRO_VIDEO, SHOW_LAUNCH_VIDEO } from '~/Actions/ActionDialogs'
import Api from '~/types'
import { searchToObject } from '~/utils'
import { defaultAccount } from './defaultData'

const queryParams = searchToObject()
let noAnimation = true
if (queryParams.noAnimation === false) {
    noAnimation = false
}
export const isInGame = window.navigator.userAgent.includes('WOWS/1')

export interface IBooster {
    id: string
    bonus: Api.BoosterBonus
    price: number
    unique: boolean
    isHidden: boolean
    isPremium: boolean
    title: string
    description: string
    restrictedWhenUserHasBoosters: Array<Api.BoostersRestrictions>
}

export interface IReward {
    id: string
    value: number
    type: string
    image: string
    flagImage: string
    tooltipImage: string
    extraImage?: string
    icon: string
    title: string
    description: string
    shipLevelId: string
    shipLevelNumber: number
    shipLevelDescription: string
    gameRewards: Array<Api.GameItemReward>
    grade: RewardGrade
    isMainReward: boolean
    descriptionLength?: number
    isDemo?: boolean
}

export interface IFeatureItemComplexity {
    description: string
    header: string
    icons: IFeatureItemComplexityIcons
    level: number
}

export interface IFeatureItemComplexityIcons {
    empty?: string
    filled?: string
    localEmpty?: string
    localFilled?: string
}

export interface IAccount extends Api.Account {
    issuedRewards: Array<IReward>
    completedActions: Array<Api.Action>
    wallet: {
        eventum: number
        gold: number
    }
}

export interface IExtraShipLevel extends Api.ExtraShipLevel {
    isFurther: boolean
}

export interface State {
    isBlurred: boolean
    blueprintsAnimationDurations: Array<number>
    blueprintsSvgData: BlueprintsSvgData
    blueprintsCount: number
    showThirdCard: boolean
    secondaryShipLevels: Array<IExtraShipLevel>
    tertiaryShipLevels: Array<IExtraShipLevel>
    isRepeating: boolean
    isLaunchingPassed: boolean
    targetLevel: number | null
    isEndReached: boolean
    isInitialLoad: boolean
    noAnimation: boolean
    appIsReady: boolean
    browserIsReady: boolean
    guideIsRunning: boolean
    isInGame: boolean
    boosters: Array<IBooster>
    rewards: Array<IReward>
    specialRewards: Array<IReward>
    upgradeBoosterId: string
    selectedBooster: IBooster | null
    shipLevel: number
    progressShipLevel: number
    animationShipLevel: number
    animationShipLevelIsInstant: boolean
    account: IAccount
    availableRewards: Array<string>
    shipLevels: Array<Api.ShipLevel>
    pendingBoostersIds: Array<string>
    isSyncEnabled: boolean
    launchingLevelId: number
    videoType: VideoType
    isIntroWatched: boolean
    actions: Array<Api.Action>
    animationStateSubtitle: string
    isWaitingRepeat: boolean
    progressFinishTime: number
    progressStartTime: number
    startersTimeLimit: number
    progressFinishTimeDefault: number
    progressStartTimeDefault: number
    currency: string
    volume: number
    isEventFinished: boolean
    isRepeatSidebarVisible: boolean
    isAnimationInProgress: boolean
    predictProgress: number
    usedStarters: Array<string>
    lastLevel: number
    actionLevels: ActionLevelMap
    closeTime: number
    isPendingIssueRewards: boolean
    isPendingGetData: boolean
    isPendingCompleteAction: boolean
    isPendingBuyBooster: boolean
    isPendingSetShipLevel: boolean
    isWorldShow: boolean
    mainRewardShipId: string
    mainRewardShipName: string
    isLoadingStore: boolean
    isSteambotBackButtonVisible: boolean
    isPauseAnimation: boolean
    nextStep: boolean
    previousStep: boolean
    isStartBoostersBought: boolean
    congratulationBackground: string
    educationBackground: string
    blueprintsBackgrounds: Array<Api.BlueprintsBackground> | []
    blueprintArts: string[]
    plugOnWeb: string
    readMoreLink: string
    sseSectionName: string
    rules: Array<Api.Rule>
    compensations: Array<CompensationItem>
    educationSteps: Array<Api.EducationStep>
    levelsCompletedBySpendingDoubloons: number
    freeLevels: number
    starterPacksMaxLevel: number
    timings: Array<number>
    missionsFinishVersion: string
    eventFinishVersion: string
    showDevPanel: boolean
    showResetButton: boolean
}

const initialState: State = {
    isBlurred: false,
    blueprintsAnimationDurations: [],
    blueprintsSvgData: {},
    blueprintsCount: 0,
    showThirdCard: true,
    secondaryShipLevels: [],
    tertiaryShipLevels: [],
    isRepeating: false,
    isLaunchingPassed: false,
    targetLevel: null,
    isEndReached: false,
    isInitialLoad: true,
    noAnimation: noAnimation,
    appIsReady: false,
    browserIsReady: false,
    guideIsRunning: false,
    isInGame: isInGame,
    boosters: [],
    rewards: [],
    specialRewards: [],
    upgradeBoosterId: '',
    selectedBooster: null,
    shipLevel: 0,
    progressShipLevel: 0,
    animationShipLevel: 0,
    animationShipLevelIsInstant: true,
    account: defaultAccount,
    availableRewards: [],
    shipLevels: [],
    pendingBoostersIds: [],
    isSyncEnabled: true,
    launchingLevelId: 99,
    videoType: null,
    isIntroWatched: false,
    actions: [],
    animationStateSubtitle: '',
    isWaitingRepeat: false,
    progressFinishTime: 0,
    progressStartTime: 0,
    progressFinishTimeDefault: 0,
    progressStartTimeDefault: 0,
    startersTimeLimit: 0,
    currency: '',
    volume: 0,
    isEventFinished: false,
    isRepeatSidebarVisible: false,
    isAnimationInProgress: false,
    isWorldShow: true,
    predictProgress: 0,
    lastLevel: 0,
    actionLevels: {
        education: 0,
        introVideo: 0,
        launchingVideo: 0,
        finalVideo: 0,
        completeBlueprints: 0,
    },
    closeTime: 0,
    isPendingIssueRewards: false,
    isPendingGetData: false,
    isPendingCompleteAction: false,
    isPendingBuyBooster: false,
    isPendingSetShipLevel: false,
    mainRewardShipId: '',
    mainRewardShipName: '',
    isLoadingStore: false,
    isSteambotBackButtonVisible: false,
    isPauseAnimation: false,
    nextStep: false,
    previousStep: false,
    isStartBoostersBought: false,
    congratulationBackground: '',
    educationBackground: '',
    blueprintsBackgrounds: [],
    blueprintArts: [],
    plugOnWeb: '',
    readMoreLink: '',
    rules: [],
    sseSectionName: '',
    compensations: [],
    educationSteps: [],
    levelsCompletedBySpendingDoubloons: 0,
    freeLevels: 0,
    starterPacksMaxLevel: 0,
    timings: [],
    missionsFinishVersion: '',
    eventFinishVersion: '',
    showDevPanel: false,
    showResetButton: false,
    usedStarters: [],
}

export const ReducerApp = (state: State = initialState, action: Actions): State => {
    switch (action.type) {
        case SET_SHOP_LOADING_STATE: {
            return {
                ...state,
                isLoadingStore: action.payload.isLoading,
            }
        }
        case SET_DEFAULT_DATES: {
            return {
                ...state,
                progressStartTime: state.progressStartTimeDefault,
                progressFinishTime: state.progressFinishTimeDefault,
            }
        }
        case SET_EVENT_START_DATE: {
            return {
                ...state,
                progressStartTime: action.payload.date,
            }
        }
        case SET_EVENT_END_DATE: {
            return {
                ...state,
                progressFinishTime: action.payload.date,
            }
        }
        case SET_PREDICT_PROGRESS: {
            return {
                ...state,
                predictProgress: action.payload.predictProgress,
            }
        }
        case SET_USED_STARTERS: {
            return {
                ...state,
                usedStarters: action.payload.usedStarters,
            }
        }
        case SET_WORLD_RENDERER_STATE: {
            return {
                ...state,
                isWorldShow: action.payload.isWorldShow,
            }
        }

        case BROWSER_IS_READY: {
            return {
                ...state,
                browserIsReady: true,
                volume: action.payload.volume,
            }
        }

        case SHOW_INTRO_VIDEO: {
            return {
                ...state,
                videoType: 'intro',
            }
        }

        case SHOW_LAUNCH_VIDEO: {
            return {
                ...state,
                videoType: 'launch',
            }
        }

        case SHOW_COMPLETE_BLUEPRINTS_VIDEO: {
            return {
                ...state,
                videoType: 'completeBlueprints',
            }
        }

        case SHOW_FINAL_VIDEO: {
            return {
                ...state,
                videoType: 'final',
            }
        }

        case SWITCH_SYNC: {
            return {
                ...state,
                isSyncEnabled: action.payload.isSyncEnabled,
            }
        }

        case APP_IS_READY: {
            return {
                ...state,
                appIsReady: true,
            }
        }

        case TURN_GUIDE_ON: {
            return {
                ...state,
                guideIsRunning: true,
            }
        }

        case TURN_GUIDE_OFF: {
            return {
                ...state,
                guideIsRunning: false,
            }
        }

        case UPDATE_AVAILABLE_REWARDS: {
            return {
                ...state,
                availableRewards: action.payload.availableRewardsIds,
            }
        }

        case UPDATE_ACCOUNT: {
            return {
                ...state,
                account: action.payload.account,
                isInitialLoad: false,
                isEndReached: action.payload.isEndReached,
                isEventFinished: action.payload.isEventFinished,
                lastLevel: action.payload.lastLevel,
                isStartBoostersBought: action.payload.isStartBoostersBought,
            }
        }

        case GET_DATA: {
            return {
                ...state,
                showThirdCard: action.payload.showThirdCard,
                mainRewardShipId: action.payload.mainRewardShipId,
                mainRewardShipName: action.payload.mainRewardShipName,
                secondaryShipLevels: action.payload.secondaryShipLevels,
                tertiaryShipLevels: action.payload.tertiaryShipLevels,
                boosters: action.payload.boosters,
                rewards: action.payload.rewards,
                specialRewards: action.payload.specialRewards,
                shipLevels: action.payload.shipLevels,
                launchingLevelId: action.payload.launchingLevelId,
                actions: action.payload.actions,
                isIntroWatched: action.payload.isIntroWatched,
                progressFinishTime: action.payload.progressFinishTime,
                progressStartTime: action.payload.progressStartTime,
                progressFinishTimeDefault: action.payload.progressFinishTime,
                progressStartTimeDefault: action.payload.progressStartTime,
                currency: action.payload.currency,
                actionLevels: action.payload.actionLevels,
                closeTime: action.payload.closeTime,
                blueprintsCount: action.payload.blueprintsCount,
                congratulationBackground: action.payload.congratulationBackground,
                educationBackground: action.payload.educationBackground,
                blueprintsBackgrounds: action.payload.blueprintsBackgrounds,
                blueprintArts: action.payload.blueprintArts,
                plugOnWeb: action.payload.plugOnWeb,
                startersTimeLimit: action.payload.startersTimeLimit,
                readMoreLink: action.payload.readMoreLink,
                sseSectionName: action.payload.sseSectionName,
                rules: action.payload.rules,
                compensations: action.payload.compensations,
                educationSteps: action.payload.educationSteps,
                levelsCompletedBySpendingDoubloons: action.payload.levelsCompletedBySpendingDoubloons,
                freeLevels: action.payload.freeLevels,
                lastLevel: action.payload.lastLevel,
                starterPacksMaxLevel: action.payload.starterPacksMaxLevel,
                timings: action.payload.timings,
                missionsFinishVersion: action.payload.missionsFinishVersion,
                eventFinishVersion: action.payload.eventFinishVersion,
                showDevPanel: action.payload.showDevPanel,
                showResetButton: action.payload.showResetButton,
            }
        }

        case SET_TIMINGS: {
            return {
                ...state,
                timings: action.payload.timings,
            }
        }

        case SET_SHIP_LEVEL: {
            return {
                ...state,
                shipLevel: action.payload.level,
            }
        }

        case UPDATE_CLIENT_STATE: {
            return {
                ...state,
                shipLevel: action.payload.shipLevel,
            }
        }

        case SET_SELECTED_BOOSTER: {
            return {
                ...state,
                selectedBooster: action.payload.booster,
            }
        }

        case CLEAR_BOOSTER_ID: {
            return {
                ...state,
                upgradeBoosterId: '',
            }
        }

        case UPDATE_ANIMATION_SUBTITLE: {
            return {
                ...state,
                animationStateSubtitle: action.payload.animationStateSubtitle,
                animationShipLevel: action.payload.level,
                animationShipLevelIsInstant: action.payload.isInstant,
            }
        }

        case UPDATE_ANIMATION_LEVEL: {
            return {
                ...state,
                animationShipLevel: action.payload.level,
            }
        }

        case SET_PROGRESS_SHIP_LEVEL: {
            return {
                ...state,
                progressShipLevel: action.payload.level,
            }
        }

        case SET_TARGET_LEVEL: {
            return {
                ...state,
                targetLevel: action.payload.level,
            }
        }

        case SET_REPEATING_STATE: {
            return {
                ...state,
                isRepeating: action.payload.isRepeating,
            }
        }

        case SHOW_REPEAT_SIDEBAR: {
            return {
                ...state,
                isRepeatSidebarVisible: true,
            }
        }

        case HIDE_REPEAT_SIDEBAR: {
            return {
                ...state,
                isRepeatSidebarVisible: false,
            }
        }

        case SET_ANIMATION_STATE: {
            return {
                ...state,
                isAnimationInProgress: action.payload.isAnimationInProgress,
            }
        }

        case SET_ISSUE_REWARDS_PENDING_STATE: {
            return {
                ...state,
                isPendingIssueRewards: action.payload.isPending,
            }
        }
        case SET_GET_DATA_PENDING_STATE: {
            return {
                ...state,
                isPendingGetData: action.payload.isPending,
            }
        }
        case SET_COMPLETE_ACTION_PENDING_STATE: {
            return {
                ...state,
                isPendingCompleteAction: action.payload.isPending,
            }
        }
        case SET_BUY_BOOSTER_PENDING_STATE: {
            return {
                ...state,
                isPendingBuyBooster: action.payload.isPending,
            }
        }
        case SET_SET_SHIP_LEVEL_PENDING_STATE: {
            return {
                ...state,
                isPendingSetShipLevel: action.payload.isPending,
            }
        }
        case UPDATE_BACK_BUTTON_VISIBILITY: {
            return {
                ...state,
                isSteambotBackButtonVisible: action.payload.isVisible,
            }
        }
        case GET_BLUEPRINTS_SVG_DATA: {
            return {
                ...state,
                blueprintsSvgData: action.payload.data,
                blueprintsAnimationDurations: action.payload.durations,
            }
        }
        case SET_PAUSE_ANIMATION: {
            return {
                ...state,
                isPauseAnimation: action.payload.isPauseAnimation,
            }
        }
        case SET_NEXT_STEP: {
            return {
                ...state,
                nextStep: action.payload.nextStep,
            }
        }
        case SET_PREVIOUS_STEP: {
            return {
                ...state,
                previousStep: action.payload.previousStep,
            }
        }
        case ENABLE_BLUR: {
            return {
                ...state,
                isBlurred: true,
            }
        }
        case DISABLE_BLUR: {
            return {
                ...state,
                isBlurred: false,
            }
        }

        default:
            return state
    }
}
