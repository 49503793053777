import { Interpolate, Button } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    makeCompensation,
    repeatBuildingProcess as repeatBuildingProcessAction,
    stopRepeatBuildingProcess as stopRepeatBuildingProcessAction,
} from '~/Actions/ActionApp'
import { openWelcomeDialog } from '~/Actions/ActionDialogs'
import ProgressBar from '~/Components/ProgressBar/ProgressBar'
import { ProgressBarController } from '~/Components/ProgressBar/ProgressBarController'
import { State } from '~/Reducers'
import RefManager, { RefManagerKeys } from '~/refManager'
import { appIsReady, isCollectRewardButtonVisible, isStartBoosterVisible } from '~/selectors'
import { compensation } from '~/wowsEntities'
import styles from './Footer.scss'
import Link from '~/Components/Link/Link'
import dwhExport from '~/api/dwhExport'
import { DWH_EXPORT_EVENTS } from '~/constants'

export interface FooterState {
    eventum: number
    isCollectRewardButtonVisible: boolean
    isBuyPremBoosterButtonVisible: boolean
    isCompensationLabelVisible: boolean
    hasAvailableRewards: boolean
    hasPendingTransactions: boolean
    appIsReady: boolean
    isInGame: boolean
    isRepeating: boolean
    isBlueprintsViewerVisible: boolean
    progress: number
    freeLevels: number
    compensations: Array<CompensationItem>
    isAnimationInProgress: boolean
}

const stateSelector = (state: State): FooterState => {
    let allRewardsHasGameReward = true
    state.ReducerApp.rewards.forEach((reward) => {
        if (state.ReducerApp.availableRewards.includes(reward.id) && allRewardsHasGameReward && reward.gameRewards.length === 0) {
            allRewardsHasGameReward = false
        }
    })
    const hasPendingTransactions: boolean = get(state.ReducerApp, 'account.pendingTransactions', false)
    const eventum = get(state.ReducerApp, 'account.wallet.eventum', 0) as number
    const hasAvailableRewards = state.ReducerApp.availableRewards.length > 0
    const isCompensationLabelVisible = state.ReducerApp.isEndReached && eventum > 0
    const isBuyPremBoosterButtonVisible = !isStartBoosterVisible(state) && !state.ReducerApp.isEndReached && !state.ReducerApp.isAnimationInProgress

    return {
        eventum: eventum,
        isCollectRewardButtonVisible: isCollectRewardButtonVisible(state),
        isBuyPremBoosterButtonVisible: isBuyPremBoosterButtonVisible,
        isCompensationLabelVisible: isCompensationLabelVisible,
        hasAvailableRewards: hasAvailableRewards,
        hasPendingTransactions: hasPendingTransactions,
        appIsReady: appIsReady(state),
        isInGame: state.ReducerApp.isInGame,
        isRepeating: state.ReducerApp.isRepeating,
        isBlueprintsViewerVisible: state.ReducerDialogs.isBlueprintsViewerVisible,
        progress: state.ReducerApp.account.progress,
        freeLevels: state.ReducerApp.freeLevels,
        compensations: state.ReducerApp.compensations,
        isAnimationInProgress: state.ReducerApp.isAnimationInProgress,
    }
}

const Footer = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isVisible, setIsVisible] = useState(false)
    const state = useSelector<State, FooterState>(stateSelector, equal)
    const [isReplayButtonVisible, setIsReplayButtonVisible] = useState<boolean>(false)

    const {
        eventum,
        isCollectRewardButtonVisible,
        isCompensationLabelVisible,
        hasAvailableRewards,
        hasPendingTransactions,
        appIsReady,
        isInGame,
        isRepeating,
        isBlueprintsViewerVisible,
        progress,
        compensations,
        isAnimationInProgress,
    } = state

    const wrapperClasses = classnames(styles.wrapper, {
        [styles.wrapperVisible]: isVisible,
    })

    useEffect(() => {
        if (progress > 0) {
            setIsReplayButtonVisible(true)
        }
    }, [progress])

    useEffect(() => {
        if (appIsReady && !isVisible) {
            setTimeout(
                () => {
                    setIsVisible(true)
                },
                isInGame ? 500 : 0,
            )
        }
    }, [appIsReady])

    const stopRepeatBuildingProcess = () => {
        dispatch(stopRepeatBuildingProcessAction(undefined, progress))
    }

    const collectRewards = () => {
        if (hasAvailableRewards) {
            dispatch(openWelcomeDialog(true))
        } else {
            dispatch(makeCompensation())
        }
    }

    const renderCompensationValue = () => {
        const compensationMultiplier = eventum
        if (isCompensationLabelVisible) {
            return (
                <div className={styles.compensationValue}>
                    <Interpolate str={t('Доступная награда: %(compensation)s')} compensation={compensation(compensationMultiplier, compensations)} />
                </div>
            )
        } else {
            return null
        }
    }

    const repeatBuildingProcess = () => {
        dispatch(repeatBuildingProcessAction())
        dwhExport.send(DWH_EXPORT_EVENTS.REPEAT_ANIMATION)
    }

    return (
        <div className={wrapperClasses}>
            <div className={styles.content}>
                {!isRepeating && (
                    <div className={styles.footerActions}>
                        {isCollectRewardButtonVisible && !hasPendingTransactions && !isAnimationInProgress && (
                            <div className={styles.compensationBlock}>
                                {renderCompensationValue()}
                                <div className={styles.collectBlock}>
                                    <Button isFlat={true} type={'orange'} onClick={collectRewards}>
                                        {t('Забрать награду')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className={styles.progress} id="progressWrapper">
                    <ProgressBar
                        ref={(ref: ProgressBarController) => {
                            RefManager.setRef(RefManagerKeys.ProgressBar, ref)
                        }}
                    />
                </div>
                <div className={styles.subtitleText}>
                    {isReplayButtonVisible && (
                        <>
                            {isRepeating ? (
                                <Link onClick={stopRepeatBuildingProcess} icon={'stop'} text={t('Остановить просмотр строительства')} />
                            ) : (
                                <Link
                                    onClick={repeatBuildingProcess}
                                    icon={'play'}
                                    text={t('Посмотреть строительство сначала')}
                                    isDisabled={isAnimationInProgress}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Footer
