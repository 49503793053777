import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { TooltipRenderProps } from 'react-joyride'
import { i18n_Props } from '~/i18n'
import styles from './GuideTooltip.scss'
import { playShipYardOver, playButtonClickSound } from '@wg/web2clientapi/sound'
import ClientApi from '~/clientApi'
import { Button } from '~/Components/Buttons/Buttons'

interface GuideTooltip_Props extends TooltipRenderProps, i18n_Props {}

class GuideTooltip extends PureComponent<GuideTooltip_Props> {
    onMouseEnter = () => {
        playShipYardOver()
    }

    setScrollLover = (isOver: boolean) => {
        ClientApi.setScrollover(isOver)
    }

    addSound(handler: (e: React.MouseEvent) => void) {
        return function (e: React.MouseEvent) {
            playButtonClickSound()
            handler(e)
        }
    }

    render() {
        const { index, isLastStep, step, backProps, primaryProps, skipProps, tooltipProps, t } = this.props
        return (
            <div
                {...tooltipProps}
                className={styles.wrapper}
                onMouseEnter={() => this.setScrollLover(true)}
                onMouseLeave={() => this.setScrollLover(false)}
            >
                {step.title && (
                    <>
                        {!index && <button onClick={skipProps.onClick} className={styles.esc}/>}
                        <div className={!index ? styles.titleCenter : styles.title}>
                            {step.title}
                            {index > 0 && <button className={styles.guideTipButton}
                                                  onClick={this.addSound(primaryProps.onClick)}/>}
                        </div>
                    </>
                )}
                {step.content && (
                    <>
                        <div className={styles.content}>{step.content}</div>
                    </>
                )}
                {index === 0 && (
                    <div onMouseEnter={this.onMouseEnter} className={styles.actionButtons}>
                        <Button onClick={this.addSound(primaryProps.onClick)} label={t('Закрыть')} />
                    </div>
                )}
            </div>
        )
    }
}

export default withTranslation()(GuideTooltip)
