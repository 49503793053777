import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React, { forwardRef, useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlueprintsSvgData } from '~/Actions/ActionApp'
import { hideBlueprintsViewer } from '~/Actions/ActionDialogs'
import ClientApi from '~/clientApi'
import AnimationsHolder from '~/Components/AnimationsHolder/AnimationsHolder'
import { State } from '~/Reducers'
import blueprintsState from '~/utils/blueprintsState'
import timer from '~/utils/timer'
import BlueprintLevel from './BlueprintLevel'
import styles from './BlueprintViewer.scss'
import { BlueprintsBackground } from '~/types'
import useResizableBlueprints from '~/utils/useResizableBlueprints'

interface BlueprintViewerState {
    isBlueprintsViewerVisible: boolean
    blueprintsCount: number
    blueprintsSvgData: BlueprintsSvgData
    animationShipLevel: number
    blueprintsAnimationDurations: Array<number>
    isRepeatSidebarVisible: boolean
    blueprintsBackgrounds: Array<BlueprintsBackground>
}

export interface BlueprintViewerRef {
    setIsInstant: (isInstant: boolean) => void
    hide: () => void
}

export interface BlueprintViewerTimeouts {
    [key: number]: ReturnType<typeof setTimeout>
}

const stateSelector = (state: State): BlueprintViewerState => {
    return {
        isBlueprintsViewerVisible: state.ReducerDialogs.isBlueprintsViewerVisible,
        blueprintsCount: state.ReducerApp.blueprintsCount,
        blueprintsSvgData: state.ReducerApp.blueprintsSvgData,
        animationShipLevel: state.ReducerApp.animationShipLevel,
        blueprintsAnimationDurations: state.ReducerApp.blueprintsAnimationDurations,
        isRepeatSidebarVisible: state.ReducerApp.isRepeatSidebarVisible,
        blueprintsBackgrounds: state.ReducerApp.blueprintsBackgrounds,
    }
}

const BlueprintViewer = forwardRef((_, ref) => {
    const dispatch = useDispatch()
    const state = useSelector<State, BlueprintViewerState>(stateSelector, equal)

    const [isInstant, setIsInstantFlag] = useState<boolean>(true)
    const blueprintsSvgDataRef = useRef<BlueprintsSvgData>({})
    const isBlueprintsViewerVisibleRef = useRef<boolean>(false)

    const {
        isBlueprintsViewerVisible,
        blueprintsCount,
        blueprintsSvgData,
        animationShipLevel,
        blueprintsAnimationDurations,
        isRepeatSidebarVisible,
        blueprintsBackgrounds,
    } = state

    const blueprintBackground = useResizableBlueprints(blueprintsBackgrounds)

    useEffect(() => {
        if (blueprintsCount > 0) {
            dispatch(getBlueprintsSvgData())
        }
    }, [blueprintsCount])

    useEffect(() => {
        if (Object.keys(blueprintsSvgDataRef.current).length === 0 && Object.keys(blueprintsSvgData).length > 0 && isInstant) {
            setIsInstant(false)
        }
    }, [blueprintsSvgData])

    useEffect(() => {
        if (!isBlueprintsViewerVisible && isBlueprintsViewerVisibleRef.current) {
            for (let index = 1; index <= blueprintsCount; index++) {
                const timerId = `blueprint-${index}`
                timer.clear(timerId)
                blueprintsState.unsetPlayingLevel(index)
            }
        }

        if (isBlueprintsViewerVisible !== isBlueprintsViewerVisibleRef.current) {
            if (isBlueprintsViewerVisible) {
                setTimeout(() => {
                    ClientApi.setScrollover(true)
                }, 300)
            } else {
                ClientApi.setDialogState(false)
                if (!isRepeatSidebarVisible) {
                    ClientApi.setScrollover(false)
                }
            }
        }

        isBlueprintsViewerVisibleRef.current = isBlueprintsViewerVisible
    }, [isBlueprintsViewerVisible])

    const blueprintBackgroundImage = {
        backgroundImage: `url(${blueprintBackground})`,
    }
    const blueprintBackgroundImageWithSteam = {
        backgroundImage: `url(${blueprintBackground}), url("./images/steam.gif")`,
    }
    const setIsInstant = (value: boolean) => {
        setIsInstantFlag(value)
    }

    const hide = () => {
        dispatch(hideBlueprintsViewer())
    }

    if (ref && typeof ref === 'function') {
        ref({
            setIsInstant,
            hide,
        })
    }

    const allSteps = Object.keys(blueprintsSvgData).map((i) => parseInt(i, 10))

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapperVisible]: isBlueprintsViewerVisible,
    })

    const steamHolderWrapper = classnames(styles.steamHolderWrapper, {
        [styles.steamHolderWrapperVisible]: isBlueprintsViewerVisible,
    })

    const onAnimationStart = useCallback((level: number) => {
        blueprintsState.setPlayingLevel(level)
    }, [])

    const onAnimationEnd = useCallback((level: number) => {
        blueprintsState.unsetPlayingLevel(level)
        blueprintsState.completeAnimation(level)
    }, [])

    return (
        <>
            <div className={wrapper} style={blueprintBackgroundImage}>
                {isBlueprintsViewerVisible && (
                    <div className={styles.levelWrapper}>
                        {allSteps.map((step) => {
                            if (step <= animationShipLevel) {
                                const duration = blueprintsAnimationDurations[step - 1]
                                return (
                                    <div className={styles.level} key={`blueprint-level-${step}`}>
                                        <BlueprintLevel
                                            index={step}
                                            lines={blueprintsSvgData[step]}
                                            duration={duration}
                                            onAnimationStart={onAnimationStart}
                                            onAnimationEnd={onAnimationEnd}
                                            isInstant={isInstant}
                                        />
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                )}
            </div>
            <div className={steamHolderWrapper}>
                <AnimationsHolder aspectRatio={1080 / 1920}>
                    <div className={styles.steamHolder}>
                        <div className={styles.steam} style={blueprintBackgroundImageWithSteam} />
                    </div>
                </AnimationsHolder>
            </div>
        </>
    )
})

export default BlueprintViewer
