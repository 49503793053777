import { searchToObject } from '~/utils'

const queryParams = searchToObject()

export const PROJECT = 'dockyard'

export const IS_DEV_MODE = queryParams.devMode === true
export const IS_SUPER_TEST = queryParams.superTest === true

export const isShowRightMouseButtonHint = false
export const isShowPlayer = false
export const HEIGHT_PLAYER = 60

export const PREM_ACCOUNT = 'wows_premium'
export const GOLD = 'gold'

export const HINT_FOR_RMB_MAX_LEVEL = 17
export const LANGUAGE_LOWER_FIRST_LETTER = ['ru', 'uk', 'es-mx', 'pt-br', 'cs', 'es', 'fr', 'pl']

export const END_DELAY = 5 * 24 * 60 * 60 * 1000

export const COMBAT_MISSIONS_FINISH_VERSION = '0.11.0'
export const EVENT_FINISH_VERSION = '0.11.4'

export const BLUEPRINT_DURATION_MODIFIER = IS_DEV_MODE ? 500 : 90

export enum BUY_BOOSTER_SOURCE {
    DOCKYARD = 'dockyard',
}

export enum GRADES {
    NORMAL = 'normal',
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
}

export enum ACTION_IDS {
    INTRO_VIDEO = 'action_video_1',
    LAUNCH_VIDEO = 'action_video_2',
    FINAL_VIDEO = 'action_video_3',
    COMPLETE_BLUEPRINTS_VIDEO = 'action_video_complete_blueprints',
    EDUCATION = 'action_education',
    LAUNCHING = 'action_launching',
    STARTERKIT_TOOLTIP = 'action_starterkit_tooltip',
    SILVER_REWARD_POPUP = 'action_silver_reward_popup',
    GOLD_REWARD_POPUP = 'action_gold_reward_popup',
}

export enum DWH_EXPORT_EVENTS {
    APP_INIT = 'dockyard-app-init', // Приложение начало запускаться
    APP_LOADED = 'dockyard-app-loaded', // Приложение запустилось
    PASS_GUIDE_STEP = 'dockyard-pass-guide-step', // Глубина просмотра обучения
    SHOW_RULES = 'dockyard-show-rules', // Показ правил
    BUY_GOLD = 'dockyard-open-buy-gold-dialog', // Клик на покупку дублонов
    REPEAT_ANIMATION = 'dockyard-repeat-animation', // Запуск повтора анимаци
    REPEAT_ANIMATION_FROM_LEVEL = 'dockyard-repeat-animation-from-level', // Панель анимаци и выставлени уровня внутри
    START_BOOSTER_CLICK = 'dockyard-start-booster-click', // Взаимодействие с банером
    SET_MULTIPLE_BOOSTER_BUY_AMOUNT = 'dockyard-set-multiple-booster-buy-amount', // Взаимодействие с "прогрессбаром" покупки
    GO_TO_SSE = 'dockyard-go-to-sse', // Переход к БЗ из окна верфи
    VIDEO_VIEWED = 'dockyard-video-viewed', // Просмотр видео целиком
    VIDEO_SKIPPED = 'dockyard-video-skipped', // Частичный просмотр видео целиком
    OPEN_LEVELS = 'dockyard-open-levels', // Открытие списка этапов
    USER_ACTIVITY_TIME = 'dockyard-activity-time', //  Время нахождения игрока в окне верфи
}

export const COLOR_ORDER = {
    '#000000': 0,
    '#FF0000': 1,
    '#FAFF00': 2,
    '#24FF00': 3,
    '#00A3FF': 4,
}
