import { AnyAction } from 'redux'
import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Player, PlayerState } from 'video-react'
import { setGetDataPendingState, switchSync } from '~/Actions/ActionApp'
import { setWorldRendererState } from '~/Actions/ActionClient'
import { hideVideoPlayer, showVideoPlayer } from '~/Actions/ActionDialogs'
import { i18n_Props } from '~/i18n'
import { State } from '~/Reducers'
import * as Api from '~/types'
import { disabledCertainVideo, getVideoAction, isRealmCn360 } from '~/utils/actions'
import styles from './VideoPlayer.scss'
import { DWH_EXPORT_EVENTS, IS_DEV_MODE } from '~/constants'
import clientApi from '~/clientApi'
import SkipDocket from '~/Components/VideoPlayer/SkipDocket'
import { playButtonClickSound } from '@wg/web2clientapi/sound'
import dwhExport from '~/api/dwhExport'

const DEBOUNCE_DELAY = 100

type OwnProps = i18n_Props

interface DispatchProps {
    switchSync: (enabled: boolean) => void
    showVideoPlayer: () => void
    hideVideoPlayer: (videoType: VideoType) => void
    setWorldRendererState: (enabled: boolean) => void
    setGetDataPendingState: () => void
}

interface MapStateToProps {
    isVideoPlayerVisible: boolean
    isVideoPlayerCanShow: boolean
    isWorldShow: boolean
    videoType: VideoType
    actions: Array<Api.Action>
    isInGame: boolean
    isPendingGetData: boolean
    volume: number
}

export type Props = MapStateToProps & OwnProps & DispatchProps

class VideoPlayer extends Component<Props> {
    player: Player
    wasOpen = false
    hiding = false

    shouldComponentUpdate(nextProps: Props) {
        return (
            this.props.isVideoPlayerCanShow !== nextProps.isVideoPlayerCanShow ||
            this.props.videoType !== nextProps.videoType ||
            this.props.isPendingGetData !== nextProps.isPendingGetData ||
            this.props.volume !== nextProps.volume
        )
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onEscape, false)
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.isVideoPlayerCanShow && this.props.isVideoPlayerCanShow && this.player && !this.wasOpen) {
            this.wasOpen = true
            this.player.subscribeToStateChange(this.handleStateChange)
            this.props.switchSync(false)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape, false)
    }

    hidePlayer = (skipped?: boolean, startTime?: number) => {
        if (this.hiding) return
        this.hiding = true
        if (this.props.videoType !== "intro") {
            this.props.setWorldRendererState(true)
            this.props.switchSync(true)
        }

        if (skipped) {
            const endTime = Date.now()
            const duration = Math.floor((endTime - startTime) / 1000)
            dwhExport.send(DWH_EXPORT_EVENTS.VIDEO_SKIPPED, { video: this.props.videoType, duration })
        } else {
            dwhExport.send(DWH_EXPORT_EVENTS.VIDEO_VIEWED, { video: this.props.videoType })
        }

        setTimeout(() => {
            this.props.hideVideoPlayer(this.props.videoType)
            this.wasOpen = false
            this.hiding = false
        }, 300)
    }

    hidePlayerDebounced = debounce(this.hidePlayer, DEBOUNCE_DELAY)

    onEscape = (e: any) => {
        if (e.keyCode === 27 && this.props.isVideoPlayerVisible) {
            e.preventDefault()
            this.hidePlayer()
        }
    }

    handleStateChange = (state: PlayerState) => {
        const { videoType, isWorldShow } = this.props
        const disableVideoForCn360 = isRealmCn360() && disabledCertainVideo(videoType)
        if (this.player && state.ended) {
            this.hidePlayerDebounced()
        }
        if (state.hasStarted && !disableVideoForCn360 && isWorldShow) {
            this.props.setGetDataPendingState()
            this.props.setWorldRendererState(false)
        }
    }

    setRef = (r: Player) => {
        if (r) {
            this.player = r
        }
    }

    onSkipClick(startTime: number) {
        clientApi.switchSoundState(true)
        playButtonClickSound()
        if (this.props.isVideoPlayerVisible) this.hidePlayer(true, startTime)
    }

    render() {
        const { isVideoPlayerCanShow, isInGame, actions, videoType } = this.props
        const videoAction = getVideoAction(actions, videoType)
        const videoUrl = videoAction && (isInGame ? videoAction.meta.internalLink : videoAction.meta.externalLink)
        const disableVideoForCn360 = isRealmCn360() && disabledCertainVideo(videoType)
        const startTime = Date.now()

        if (disableVideoForCn360) {
            this.props.setGetDataPendingState()
            this.hidePlayer()
        }

        return this.props.isVideoPlayerVisible ? (
            <div className={`${styles.wrapper} ${isVideoPlayerCanShow && !disableVideoForCn360 ? styles.visible : ''}`}>
                {isVideoPlayerCanShow && videoUrl && (
                    <>
                        <Player volume={this.props.volume} autoPlay={true} fluid className={styles.player} ref={this.setRef}>
                            <source src={videoUrl} />
                        </Player>
                    </>
                )}
                {this.props.isVideoPlayerVisible && <SkipDocket hidePlayer={this.onSkipClick.bind(this, startTime)} />}
                {IS_DEV_MODE && <div className={styles.videoId}>{this.props.videoType}</div>}
            </div>
        ) : null
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): MapStateToProps => ({
    isVideoPlayerCanShow: state.ReducerDialogs.isVideoPlayerVisible && state.ReducerApp.browserIsReady,
    isVideoPlayerVisible: state.ReducerDialogs.isVideoPlayerVisible,
    isWorldShow: state.ReducerApp.isWorldShow,
    videoType: state.ReducerApp.videoType,
    actions: state.ReducerApp.actions,
    isInGame: state.ReducerApp.isInGame,
    volume: state.ReducerApp.volume,
    isPendingGetData: state.ReducerApp.isPendingGetData,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        showVideoPlayer: () => {
            dispatch(showVideoPlayer())
        },
        setGetDataPendingState: () => {
            dispatch(setGetDataPendingState(false))
        },
        hideVideoPlayer: (videoType: VideoType) => {
            dispatch(hideVideoPlayer(videoType))
        },
        setWorldRendererState: (enabled: boolean) => {
            dispatch(setWorldRendererState(enabled))
        },
        switchSync: (enabled: boolean) => {
            dispatch(switchSync(enabled))
        },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VideoPlayer))
