import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'
import { ItemTypes } from '@wg/wows-entities/const'
import classnames from 'classnames'
import React, { memo } from 'react'
import Rays from '~/Components/Rays/Rays'
import { IReward } from '~/Reducers/ReducerApp'
import styles from './RewardShip.scss'

export interface IRewardShip_Props {
    id: string
    isCollected?: boolean
    isPremium?: boolean
    fixedTooltip?: boolean
    raysVisible?: boolean
    reward?: IReward
    isBig?: boolean
}

const RewardShip = (props: IRewardShip_Props) => {
    const { id, raysVisible, reward, isBig } = props

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapperBig]: !!isBig,
    })

    const back = classnames(styles.image, styles.shipBack, {
        [styles.imageBig]: !!isBig,
    })

    const front = classnames(styles.image, {
        [styles.imageBig]: !!isBig,
    })
    const frontStyles = {
        backgroundImage: `url(${reward.image})`,
    }

    const raysHolder = classnames(styles.raysHolder, {
        [styles.raysHolderVisible]: !!raysVisible,
    })

    const shipNameHolder = classnames(styles.shipNameHolder, {
        [styles.shipNameOnRays]: !!raysVisible,
    })

    const renderShipNameHolder = () => {
        return (
            <WoWSEntity
                type={ItemTypes.VEHICLES}
                id={id}
                presentation={{
                    withTooltip: false,
                    hideNationIcon: true,
                }}
            />
        )
    }

    return (
        <div className={wrapper}>
            <div className={back} />
            <div className={raysHolder}>
                <Rays className={styles.rays} color={'yellow'} />
            </div>
            <div className={front} style={frontStyles} />
            <div className={shipNameHolder}>{renderShipNameHolder()}</div>
        </div>
    )
}

export default memo(RewardShip)
